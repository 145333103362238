.banner {
	display: flex;
	overflow: hidden;
	position: relative;
	flex-direction: row;
}

.image {
	width: 50%;
}

.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.content {
	position: relative;
	width: 50%;
	min-height: 100%;

	background-image: url("https://sales.maximum-chery.ru/img/pics/backgrounds/bg-text.jpg");
	background-repeat: no-repeat;
	background-size: auto 100%;

	padding: 24px 24px;
}

.content .subtitle {
	font-size: 28px;
	font-weight: 500;
	margin-bottom: 20px;
}

.content li {
	margin-top: 12px;
	margin-left: -24px;
}

.title {
	font-weight: 600;
	font-size: 28px;
	margin-bottom: 20px;
}

strong {
	color: #00afdc;
	font-size: 38px;
}

.button {
	display: inline-block;
	background-color: #a3171f;
	border: none;
	color: white;
	padding: 12px 24px;
	font-size: 20px;
	margin-top: 24px;
	cursor: pointer;
	transition: background-color 0.2s;
	user-select: none;
}

.button:hover {
	color: white;
	background-color: #5e0808;
}

.footer {
	font-size: 18px;
	line-height: 140%;
	font-weight: 400;
	margin-top: 12px;
	width: 90%;
}


.line {
	font-size: 18px;
	line-height: 140%;
	font-weight: 700;
}

@media screen and (max-width: 992px) {
	.image {
		width: 100%;
	}

	.banner {
		flex-direction: column;
	}

	.content {
		width: fit-content;
		background-size: auto 100%;
		padding: 24px 16px;
	}

	.line {
		font-size: 16px;
	}

	.footer {
		font-size: 16px;
	}
}
