@font-face {
	font-weight: 300;
	font-family: "Tactic Sans";
	font-style: normal;
	src: url("../fonts/TacticSans/TacticSans-Lgt.eot");
	src: local("Tactic Sans Light"), local("TacticSans-Lgt"), url("../fonts/TacticSans/TacticSans-Lgt.eot?#iefix") format("embedded-opentype"),
		url("../fonts/TacticSans/TacticSans-Lgt.woff2") format("woff2"), url("../fonts/TacticSans/TacticSans-Lgt.woff") format("woff"),
		url("../fonts/TacticSans/TacticSans-Lgt.ttf") format("truetype");
}

@font-face {
	font-weight: normal;
	font-family: "Tactic Sans";
	font-style: normal;
	src: url("../fonts/TacticSans/TacticSans-Reg.eot");
	src: local("Tactic Sans"), local("TacticSans-Reg"), url("../fonts/TacticSans/TacticSans-Reg.eot?#iefix") format("embedded-opentype"),
		url("../fonts/TacticSans/TacticSans-Reg.woff2") format("woff2"), url("../fonts/TacticSans/TacticSans-Reg.woff") format("woff"),
		url("../fonts/TacticSans/TacticSans-Reg.ttf") format("truetype");
}

@font-face {
	font-weight: 500;
	font-family: "Tactic Sans";
	font-style: normal;
	src: url("../fonts/TacticSans/TacticSans-Med.eot");
	src: local("Tactic Sans Med"), local("TacticSans-Med"), url("../fonts/TacticSans/TacticSans-Med.eot?#iefix") format("embedded-opentype"),
		url("../fonts/TacticSans/TacticSans-Med.woff2") format("woff2"), url("../fonts/TacticSans/TacticSans-Med.woff") format("woff"),
		url("../fonts/TacticSans/TacticSans-Med.ttf") format("truetype");
}

@font-face {
	font-weight: bold;
	font-family: "Tactic Sans";
	font-style: normal;
	src: url("../fonts/TacticSans/TacticSans-Bld.eot");
	src: local("Tactic Sans Bold"), local("TacticSans-Bld"), url("../fonts/TacticSans/TacticSans-Bld.eot?#iefix") format("embedded-opentype"),
		url("../fonts/TacticSans/TacticSans-Bld.woff2") format("woff2"), url("../fonts/TacticSans/TacticSans-Bld.woff") format("woff"),
		url("../fonts/TacticSans/TacticSans-Bld.ttf") format("truetype");
}

@font-face {
	font-weight: normal;
	font-family: "Rouble Sans";
	font-style: normal;
	src: url("../fonts/RoubleSans/RoubleSans.eot?") format("eot"), url("../fonts/RoubleSans/RoubleSans.woff") format("woff"),
		url("../fonts/RoubleSans/RoubleSans.ttf") format("truetype");
}

@font-face {
	font-family: TacticSansExtExd-Bld;
	src: local("TacticSansExtExd-Bld"), url(../fonts/TacticSans/TacticSansExtExd-Bld.woff2) format("woff2"),
		url(../fonts/TacticSans/TacticSansExtExd-Bld.woff) format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "HarmonyOS";
	src: url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Regular.ttf");
	src: local("HarmonyOS_Sans_Regular"), url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Regular.ttf") format("truetype"),
	url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "HarmonyOS";
	src: url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Black.ttf");
	src: local("HarmonyOS_Sans_Black"), url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Black.ttf") format("truetype"),
	url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "HarmonyOS";
	src: url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Bold.ttf");
	src: local("HarmonyOS_Sans_Bold"), url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Bold.ttf") format("truetype"),
	url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "HarmonyOS";
	src: url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Medium.ttf");
	src: local("HarmonyOS_Sans_Medium"), url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Medium.ttf") format("truetype"),
	url("../fonts/HarmonyOS/HarmonyOS_Sans_SC_Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

a {
	text-decoration: none;
	color: black;
	transition: color 0.2s;
}

a:hover {
	color: #a3171f;
}

* {
	font-family: "HarmonyOS", sans-serif;
}

html {
	margin: 0;
	padding: 0;
}

ul,
li {
	/* list-style-type: none; */
}
