.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	padding: 12px 0;
	font-size: 14px;
	font-weight: 500;
}

.logo {
}

.logoText {
	margin-left: 12px;
}

.location,
.logoText {
	display: none;
}

@media screen and (min-width: 992px) {
	.location {
		display: block;
	}
}
