.root {
	max-width: 100%;
	width: calc(100% - 32px);
	margin: auto;
	padding: 20px 16px;
}

.noPaddingTop {
	padding-top: 0;
}

.noPaddingBottom {
	padding-bottom: 0;
}

@media screen and (min-width: 992px) {
	.root {
		max-width: 1300px;
		padding: 40px 16px;
	}

	.noPaddingTop {
		padding-top: 0;
	}

	.noPaddingBottom {
		padding-bottom: 0;
	}
}
